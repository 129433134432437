.timeRangeWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: center;
  .timeRangeCustom {
    display: flex;
    gap: 1rem;
    .timeRangeDate {
      display: inline-flex;
      align-items: center;
      gap: 1rem;
      label {
        color: #232a34;
        font-family: 'DM Sans';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.125rem;
      }
      button {
        padding: 0;
        margin: 0;
        height: 2.1rem;
      }
      .input {
        color: #717e90;
        font-family: 'DM Sans';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        padding: 0 0.5rem;

        &::placeholder {
          color: #717e90;
          font-family: 'DM Sans';
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem;
        }
      }
    }
    :global {
      .p-inputtext.p-component.p-inputtext {
        border: none;
      }
      .p-calendar.p-inputwrapper.p-calendar-w-btn.p-calendar-w-btn-right {
        border: 1px solid #ccd8ea;
        transition:
          background-color 0.2s,
          color 0.2s,
          border-color 0.2s,
          box-shadow 0.2s;
        appearance: none;
        border-radius: 0.25rem;
      }
      .p-datepicker-trigger.p-button.p-button-icon-only {
        background-color: transparent;
        background: transparent;
        border: none;
      }
    }
  }
  .timeRange {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    justify-content: flex-end;
    .label {
      display: flex;
      color: #232a34;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.125rem;
    }
    .filter {
      display: flex;
    }
  }
}
