.tilesAndCharts {
  display: flex;
  position: relative;
  width: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 1.75rem;
}
.searchAndFilter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2.5rem;
  flex-wrap: wrap;
  gap: 1.2rem;
}

.selectFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dfe7f3;
  span {
    font-size: 1.2rem;
  }
}

.clearSelect {
  display: flex;
  align-items: center;
}

.count {
  color: #232a34;
  font-family: 'DM Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  margin-top: 1.5rem;
  line-height: 1.5rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.headerReport {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  h6 {
    margin: 5px;
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    &.error {
      color: #f25844;
    }
  }
}

.createReport {
  display: inline-flex;
  align-items: flex-end;
  align-self: flex-end;
  height: 100%;
  gap: 0.5rem;
  flex-grow: 1;
  .label {
    color: #232a34;
    font-family: 'DM Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 1.8rem;
    line-height: 1.375rem;
  }
  .date {
    color: #717e90;
    font-family: 'DM Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 1.8rem;
    line-height: 1.5rem;
  }
}
