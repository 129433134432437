.checkSummary {
  display: flex;
  gap: 1.75rem;
  flex-grow: 1;
  .charts {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 56.75rem;
    gap: 1.88rem;
    .tiles {
      display: flex;
      flex-direction: row;
    }
    .doughnuts {
      display: flex;
      gap: 1.75rem;
      .legendLabel {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        min-width: 6rem;
        .colorBox {
          display: flex;
        }
      }
      .doughnutTileWrapper {
        flex-grow: 1;
        width: 48%;

        &:global {
          @media screen and (max-width: 1544px) {
            width: auto;
          }
        }
        .tile {
          flex-grow: 1;
        }
      }
    }
    .checkEvolution {
      display: flex;
      flex-grow: 1;
    }
  }
}

.date {
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-end;

  .input {
    color: #717e90;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 0 0.5rem;

    &::placeholder {
      color: #717e90;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }
  :global {
    .p-inputtext.p-component.p-inputtext {
      border: none;
    }
    .p-calendar.p-inputwrapper.p-calendar-w-btn.p-calendar-w-btn-right {
      border: 1px solid #ccd8ea;
      transition:
        background-color 0.2s,
        color 0.2s,
        border-color 0.2s,
        box-shadow 0.2s;
      appearance: none;
      border-radius: 0.25rem;
    }
    .p-datepicker-trigger.p-button.p-button-icon-only {
      background-color: transparent;
      background: transparent;
      border: none;
    }
  }
}
